<template>
  <div class="personal container">
    <home-header></home-header>
    <div class="scoller grid_content" :v-infinite-scroll="false" style="overflow:auto;-webkit-overflow-scrolling: touch">
        <div class="has_header">
            <div class="personal_container">
                <div class="user_head flex">
                    <div class="user_portrait">
                        <img src="https://global-cdn.bigplayers.com/m/img/yellow/uc_portrait_bg.png?t=2024102110" alt="">
                    </div>
                    <div class="user_login flex_center" v-show="!userInfo" @click="toPage('/login')">Login/Register</div>
                    <div class="user_info flex_start_center"  v-show="userInfo">
                        <div class="email_icon">
                            <img src="https://global-cdn.bigplayers.com/m/img/icon/uc_mail.png?t=2024102216" alt="">
                        </div>
                        <div class="user_email">{{ userInfo.email }}</div>
                    </div>
                </div>
                <div class="order_msg">
                    <div class="coupon-cell flex_sb coupon-cell_line">
                        <div class="cell_title strong">My Order</div>
                        <div class="cell_more flex" @click="toPage('/order')">
                            <div class="icon_text">View all Orders</div>
                            <div class="cell_icon flex_center">
                                <svg t="1729158554238" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3634" width="16" height="16"><path d="M383.291616 808.954249c-5.175883 0-10.353812-1.950422-14.338566-5.862521-8.064676-7.919367-8.182356-20.877493-0.26299-28.942169l273.602402-278.620695L368.69006 216.907145c-7.919367-8.064676-7.801686-21.022803 0.26299-28.942169 8.065699-7.918343 21.022803-7.80271 28.942169 0.26299l287.685141 292.960285c7.818059 7.961322 7.818059 20.717857 0 28.67918L397.895219 802.826692C393.887952 806.907637 388.591319 808.954249 383.291616 808.954249z" p-id="3635" fill="#16181c"></path></svg>
                            </div>
                        </div>
                    </div>
                    <div class="order_list flex">
                        <div class="order_tab " v-for="(item,index) in orderList" :key="index" @click="toPage('/order')">
                            <div class="tab_icon">
                                <img :src="item.url" alt="">
                                <span class="badge" v-if="item.count&&item.count>0">{{ item.count }}</span>
                            </div>
                            <div class="tab_text">{{ item.text }}</div>
                        </div>
                        
                    </div>
                    
                </div>
                <div class="operate_list">
                    <div class="coupon-cell flex_sb">
                        <div class="cell_title strong">General functions</div>       
                    </div>
                    <div class="operate_list_cell">
                        <div class="coupon-cell flex_sb coupon-cell_line" v-for="(item,index) in operateList" :key="index" @click="toPage(item.path)">
                            <div class="cell_title flex_center">
                                <div class="title_icon">
                                    <img :src="item.url" alt="">
                                </div>
                                <div class="title_text">{{ item.text }}</div>
                            </div>
                            <div class="cell_more flex">
                                <div class="cell_icon flex_center">
                                    <svg t="1729158554238" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3634" width="16" height="16"><path d="M383.291616 808.954249c-5.175883 0-10.353812-1.950422-14.338566-5.862521-8.064676-7.919367-8.182356-20.877493-0.26299-28.942169l273.602402-278.620695L368.69006 216.907145c-7.919367-8.064676-7.801686-21.022803 0.26299-28.942169 8.065699-7.918343 21.022803-7.80271 28.942169 0.26299l287.685141 292.960285c7.818059 7.961322 7.818059 20.717857 0 28.67918L397.895219 802.826692C393.887952 806.907637 388.591319 808.954249 383.291616 808.954249z" p-id="3635" fill="#16181c"></path></svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    </div>
  </div>
</template>

<script>
import homeHeader from "../../components/header.vue";
import cookies from "../../utils/cookie";
export default {
    components:{
       homeHeader,  
    },
    data(){
        return{
            userInfo:'',
            orderList:[
                {text:"To be paidr",url:"https://global-cdn.bigplayers.com/m/img/yellow/uc_unpaid.png?t=2024102110",id:"1",code:'unPay',count:0},
                {text:"Processing",url:"https://global-cdn.bigplayers.com/m/img/yellow/uc_processing.png?t=2024102110",id:"2",code:'delivery',count:0},
                {text:"Completed",url:"https://global-cdn.bigplayers.com/m/img/yellow/uc_finished.png?t=2024102110",id:"3",code:'company',count:0},
                {text:"Refund / after sales",url:"https://global-cdn.bigplayers.com/m/img/yellow/uc_aftersale.png?t=2024102110",id:"4",code:'refund',count:0},
            ],
            operateList:[
                {text:'Invite friends',url:'https://global-cdn.bigplayers.com/m/img/yellow/uc_invite.png?t=2024102110',id:1,path:'/invitation'},
                {text:'Account security',url:'https://global-cdn.bigplayers.com/m/img/yellow/uc_safe.png?t=2024102110',id:2,path:'/security'},
                {text:'Help',url:'https://global-cdn.bigplayers.com/m/img/yellow/uc_help.png?t=2024102110',id:3,path:'/help'},
            ]
        }
    },
    created(){
        this.userInfo = cookies.getUserInfo()
        this.getData()
        console.log('userInfo',this.userInfo)
    },
    methods:{
        toPage(path){
            this.$router.push(path)
        },
        getData(){
            this.$axiosApi.getOrderCount().then(res=>{
                this.orderList.map(v=>{
                    if(v.code=='unPay'){
                        v.count = res.data.unPay
                    }
                    if(v.code=='delivery'){
                        v.count = res.data.delivery
                    }
                    if(v.code=='company'){
                        v.count = res.data.company
                    }
                    if(v.code=='refund'){
                        v.count = res.data.refund
                    }
                    return v
                })
                console.log('this.orderList',this.orderList)
            })
        }
    },
    computed:{
        token(){console.log('token',this.$store.state.token)}
    }
}
</script>

<style lang="scss" scoped>
.personal{
    background:#f6f6f8;
    .personal_container{
        padding:28px 16px 16px;
        .user_head{
            margin-bottom:18px;
            .user_portrait{
                width:64px;
                height: 64px;
                border-radius: 50%;

            }
            .user_login{
                font-size: 18px;
                margin-left: 20px;
                font-weight: 500;
            }
            .user_info{
                margin-left: 20px;
                .email_icon{
                    width:18px;
                    height: 18px;
                    margin-right:6px;

                }
                .user_email{
                    color:#161619;
                    font-size: 12px;
                }
               
            }
        }
        .order_msg{
            border-radius: 10px;
            background:#fff;
            margin-bottom:10px;
            .order_list{
                padding-top:25px;
                padding-bottom:10px;
                .order_tab{
                    width:25%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    cursor: pointer;
                    .tab_icon{
                        width:38px;
                        position: relative;
                        .badge{
                            position: absolute;
                            top:-12px;
                            right:-12px;
                            // padding:2px;
                            width:16px;
                            height: 16px;
                            background:#ff4b3d;
                            color:#fff;
                            font-size: 10px;
                            border-radius: 50%;
                        }
                    }
                    .tab_text{
                        font-size:12px;
                        margin-top:10px;
                    }
                }
            }
        }
        .operate_list{
            border-radius: 10px;
            background:#fff;
            .operate_list_cell{
                padding:0 16px;
                font-size: 14px!important;
                color:#222425;
            }
        }
    }
}

</style>